import React from 'react'
import EventCard from '../simple/EventCard'

const Steps2 = ({elem}) => {
  return (
    <div className="box_card_area pt-90 pb-80">
		<div className="container">
			<div className="row">
                {elem?.elements?.map((card, index) =>
                    <EventCard card={card} key={index}/>
                )}
			</div>
		</div>
	</div>
  )
}

export default Steps2