import React, { useEffect } from 'react'
import PageTop from '../../components/simple/PageTop'
import useFetchInfo from '../../hooks/useFetchInfo.hook';
import { useNavigate, useParams } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';
import Preloader from '../../components/simple/Preloader';
import MediaDetailContainer from '../../components/complex/MediaDetailContainer';
import MediaServices from '../../services/general/mediaServices';

const ProjectDetail = () => {
    const {slug} = useParams();
    const {lang, localizationsItems} = useLangContext();
    const navigate = useNavigate();
    const {lang: lang2} = useParams();

    const {info, loading} = useFetchInfo(MediaServices.view, slug);

    useEffect(() => {
        if(lang){
            if(lang !== lang2) {
                navigate(`/${lang}/media/${slug}`)
            }
        }
    }, [lang])

  return (
    <>{loading ?
        <Preloader/>
    :
    <div>
        <PageTop item={info} parentLink={`/${lang}/media`} parentName={localizationsItems?.media}/>
        {info && <MediaDetailContainer item={info}/>}
    </div>
    }
    </>
  )
}

export default ProjectDetail