import React from 'react'
import Fancybox from './FancyBox'
import OwlCarousel from 'react-owl-carousel';
import img from "../../images/placeholder.png"

const Gallery = ({elem}) => {
    const options = {
        dots: false,
        nav: true,
        navText: ["<i class='fa fa-long-arrow-left''></i>", "<i class='fa fa-long-arrow-right''></i>"],
        margin:30,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            992: {
                items: 3
            },
            1200: {
                items: 3
            },
            1920: {
                items: 3
            }
        }
    }

  return (
	<div className="our-service projects-back" 
    style={{backgroundImage: `url(${elem.elements[0]})`}}
    >
		<div className="container">
			<div className="row">
				<div className="col-lg-12 wow fadeInRight mb-40" data-wow-delay="0.4s">
					<div className="section_title text_center white">
						{elem.subtitle && <div className="section_sub_title uppercase mb-3">
							<h6>{elem.subtitle}</h6>
						</div>}
						{elem.title && <div className="projects-title">
							<h1>{elem.title}</h1>
						</div>}
						<div className="em_bar">
							<div className="em_bar_bg"></div>
						</div>
					</div>
				</div>
			</div>
            <Fancybox
                    options={{
                        Carousel: {
                        infinite: false,
                        },
                    }}
                >
                <OwlCarousel className="videos-slider" {...options}>
                        {elem.elements?.slice(1, elem.elements.length).map((card, index) =>
                            <a data-fancybox="gallery" href={card} className="col-sm-6 col-md-4" key={index}>
                                <div className="project-item">
                                    <div className="project-image">
                                        <img src={card} alt="" 
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; 
                                            currentTarget.src = img
                                        }}
                                        />
                                    </div>
                                </div>
                            </a>
                        )}
                </OwlCarousel>
            </Fancybox>
		</div>
	</div>
  )
}

export default Gallery