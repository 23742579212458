import React from 'react'
import { useLangContext } from '../../i18n/ProvideLang';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import Utils from '../../services/utils';
import AppServices from '../../services/general/appServices';

const ApplicationModal = ({show, setShow}) => {
    const {localizationsItems} = useLangContext();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [success, setSuccess] = useState();

    const handleClose = () => setShow(false);

    const {handleSubmit, register, reset, control, formState: {errors}} = useForm();

    const CustomSubmit = async (data) => {
        setLoading(true);

        let newData = {
            fields: {
                ...data
            }
        }

        let query = new URLSearchParams(window.location.search);

        if(query.toString()){
            query.get('utm_source') && (newData.utm_source = query.get('utm_source'));
            query.get('utm_medium') && (newData.utm_medium = query.get('utm_medium'))
            query.get('utm_campaign') && (newData.utm_campaign = query.get('utm_campaign'))
            query.get('utm_content') && (newData.utm_content = query.get('utm_content'))
            query.get('utm_term') && (newData.utm_term = query.get('utm_term'))
        }else{
            Utils.getCookie('utm_source') && newData.append('utm_source', Utils.getCookie('utm_source'));
            Utils.getCookie('utm_medium') && newData.append('utm_medium', Utils.getCookie('utm_medium'));
            Utils.getCookie('utm_campaign') && newData.append('utm_campaign', Utils.getCookie('utm_campaign'));
            Utils.getCookie('utm_content') && newData.append('utm_content', Utils.getCookie('utm_content'));
            Utils.getCookie('utm_term') && newData.append('utm_term', Utils.getCookie('utm_term'));
        }

        const res = await AppServices.requestTemplate(newData);

        if(res.statusCode === 200){
            setSuccess(true)
            setVisible(true)
            setTimeout(() => {
                setVisible(false)
                reset();
            }, 3000);
        }else{
            console.log(res)
            setSuccess(false)
        }
        setLoading(false)
    }

  return (
    <Modal show={show} onHide={handleClose} centered className="modal-form">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
			<h3>{localizationsItems?.book_appointment}</h3>
			<form className="mt-15" onSubmit={handleSubmit(CustomSubmit)}>
                {visible &&
                    <>{success ?
						<div className="successform">
							<p>{localizationsItems?.form_success}</p>
						</div>
                        :
						<div className="errorform">
							<p>{localizationsItems?.form_fail}</p>
						</div>
                        }
                    </>
                }
                <div className="row justify-content-end">
                    <div className="col-12">
                        <div className="form_box mb-20">
                            <input type="text" {...register('name')} placeholder={localizationsItems?.name}/>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form_box mb-20">
                            <input type="text" {...register('email')} placeholder={localizationsItems?.email}/>
                        </div>
                    </div>
                    <div className="col-lg-auto">
                        <button className="dtbtn">{localizationsItems?.send_app}</button>
                    </div>
                </div>
			</form>
        </Modal.Body>
    </Modal>
  )
}

export default ApplicationModal