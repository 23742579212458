import React from 'react'
import img from "../../images/placeholder.png"
import { NavLink } from 'react-router-dom'
import { useLangContext } from '../../i18n/ProvideLang'

const MediaCard = ({card}) => {
    const {lang} = useLangContext();

  return (
    <NavLink className="col-md-6" to={`/${lang}/media/${card.slug}`}>
        <div className='media-card'>
            <img src={card.preview_image ? card.preview_image : img} alt={card.title}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src = img
                }}
            />
            <div className='media-img'></div>
            <div className='media-card-content'>
                <h4>{card.title}</h4>
                {card.published_at && <div className='date'>{card.published_at}</div>}
            </div>
        </div>
    </NavLink>
  )
}

export default MediaCard