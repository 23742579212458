import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import ArticleServices from '../../services/general/articleServices'
import { useLangContext } from '../../i18n/ProvideLang';
import { NavLink } from 'react-router-dom';
import img from "../../images/placeholder.png"

const FooterArticles = () => {
    const {lang, localizationsItems} = useLangContext();

    const {
        items,
    } = useFetchItems(ArticleServices.index)

  return (
    <div className="col-lg-3 col-md-6 col-sm-12">
        <div id="em-recent-post-widget">
            <div className="single-widget-item">
                <h4 className="widget-title pb-3">{localizationsItems?.recent_news}</h4>
                {items?.items?.slice(0,2).map((card, index) => 
                    <div className="popular-post-item active pb-3" key={index}>
                        <div className="popular-post-image mr-3">
                            <NavLink to={`/${lang}/articles/${card.slug}`}>
                                <img src={card.image} alt="gallery1"
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img
                                }}
                                />
                            </NavLink>
                        </div>
                        <div className="popular-post-text">
                            <h6><NavLink to={`/${lang}/articles/${card.slug}`}>
                                    {card.title}
                                </NavLink>
                            </h6>
                            <span className="rcomment">{card.date}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    </div>
  )
}

export default FooterArticles