import React from 'react'
import img from "../../images/placeholder.png"

const WhyChooseUs = ({elem}) => {
  return (
	<div className="choose_us_area top_feature two pt-80 pb-80">
		<div className="container">
			<div className="row">
				<div className="col-lg-9 wow fadeInLeft" data-wow-delay="0.4s">
					<div className="section_title text_left mb-50 mt-3">
						<div className="section_sub_title uppercase mb-3">
							<h6>{elem.subtitle}</h6>
						</div>
						<div className="section_main_title">
							<h1>{elem.title}</h1>
						</div>
						<div className="em_bar">
							<div className="em_bar_bg"></div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-lg-6 wow fadeInLeft" data-wow-delay="0.4s">
					<div className="row">
                        {elem.elements?.map((card, index) =>
                            <div className="col-lg-6 col-md-6 col-sm-12" key={index}>
                                <div className="whychoose_flipbox mb-30">
                                    <div className="whychoose_flipbox_font">
                                        <div className="whychoose_flipbox_inner">
                                            <div className="whychoose_flipbox_icon">
                                                <div className="icon">
                                                    <img src={card.image ? card.image : img} alt=""
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; 
                                                            currentTarget.src = img
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flipbox_title">
                                                <h3>{card.title}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="whychoose_flipbox_back">
                                        <div className="whychoose_flipbox_inner">
                                            <div className="flipbox_title">
                                                <h3>{card.subtitle}</h3>
                                            </div>
                                            <div className="flipbox_desc pt-3"
                                            dangerouslySetInnerHTML={{__html: card.description}}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
					</div>
				</div>
				<div className="col-lg-6 wow fadeInRight" data-wow-delay="0.4s">
					<div className="choose_content"
                    dangerouslySetInnerHTML={{__html: elem.description}}>
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default WhyChooseUs