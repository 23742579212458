import React, { useEffect, useState } from 'react'
import AppServices from '../../services/general/appServices'
import useFetchItems from '../../hooks/useFetchItems'
import { NavLink } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';
import MobMenu from './MobMenu';
import ApplicationModal from '../modals/ApplicationModal';
import { Dropdown } from 'react-bootstrap';
import img from "../../images/placeholder.png"

const Header = ({item}) => {
	const {lang, changeLanguage, localizationsItems, languages} = useLangContext();
    const [logo, setLogo] = useState();
    const [sticky, setStiky] = useState();
    const [show, setShow] = useState();
    const [enabledButton, setEnabledButton] = useState();

    const {
        items,
    } = useFetchItems(AppServices.menu, 'main-menu')

    useEffect(() => {
        if(item){
            setLogo(item.find(it => it.type === "logo")?.value);
            setEnabledButton(item.find(it => it.type === "enabled-button")?.value)
        }
    }, [item])

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if(scrolled > 80){
            setStiky(true)
        }else{
            setStiky(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible)
    }, [])

  return (
    <>
	<div id="sticky-header" className={sticky ? "navibar_nav_manu transparent_menu white d-md-none d-lg-block d-sm-none d-none sticky" : "navibar_nav_manu transparent_menu white d-md-none d-lg-block d-sm-none d-none"}>
		<div className="container">
			<div className="row">
				<div className="col-auto">
					<div className="logo mt-4">
						<NavLink className="logo_img" to={`/${lang}`}>
							<img src={logo} alt="logo" 
                            />
						</NavLink>
						<NavLink className="main_sticky" to={`/${lang}`}>
							<img src={logo} alt="logo" 
                            />
						</NavLink>
					</div>
				</div>
				<div className="col">
					<nav className="navibar_menu">
						<ul className="nav_scroll">
                            {items?.map((it, index) =>
                                <li key={index}><NavLink to={it.value} target={it.type}>{it.title} </NavLink>
                                    {it.items &&
                                    <ul className="sub-menu">
                                        {it.items?.map((el, index) =>
                                            <li key={index}><NavLink to={el.value} target={el.type}>{el.title}</NavLink></li>
                                        )}
                                    </ul>
                                    }
                                </li>
                            )}
						</ul>
                        {languages?.length > 1 && <div className="dropdown-dollar">
										<Dropdown id="dropdown-basic">
											<Dropdown.Toggle>
												<span>{lang}</span>
											</Dropdown.Toggle>
											<Dropdown.Menu >
												{languages.map((lang, index) =>
													<Dropdown.Item key={index} onClick={() => changeLanguage(lang.slug)}>{lang.title}</Dropdown.Item>
												)}
											</Dropdown.Menu>
										</Dropdown>
									</div>}
						{enabledButton && <div className="donate-btn-header">
							<button className="dtbtn" onClick={() => setShow(true)}>{localizationsItems?.header_button}</button>
						</div>}
					</nav>
				</div>
			</div>
		</div>
	</div>
    <MobMenu items={items} logo={logo}/>
    {show && <ApplicationModal show={show} setShow={setShow}/>}
    </>
  )
}

export default Header