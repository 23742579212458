import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import ArticleServices from '../../services/general/articleServices'
import { useLangContext } from '../../i18n/ProvideLang'
import { NavLink } from 'react-router-dom'
import img from "../../images/placeholder.png"

const RecentArticles = () => {
    const {lang, localizationsItems} = useLangContext();

    const {
        items,
    } = useFetchItems(ArticleServices.index)
  return (
    <div id="em_recent_post_widget-6" className="widget widget_recent_data">
        <div className="single-widget-item">
            <h2 className="widget-title">{localizationsItems?.recent_news}</h2>		
            {items?.items?.slice(0,5).map((card, index) =>		
                <div className="recent-post-item" key={index}>
                    <div className="recent-post-image">
                        <NavLink to={`/${lang}/articles/${card.slug}`}>
                            <img width="100" height="80" src={card.image} alt="blog-list1"
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; 
                                currentTarget.src = img
                            }}
                            />					
                        </NavLink>
                    </div>
                    <div className="recent-post-text">                       
                        <h4><NavLink to={`/${lang}/articles/${card.slug}`}>
                            {card.title}
                            </NavLink>
                        </h4>					
                        <span className="rcomment">{card.date}</span>
                    </div>
                </div>
            )}
        </div>
    </div>	
  )
}

export default RecentArticles