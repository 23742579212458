import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import ProjectServices from '../../services/general/projectServices'
import ProjectCard from '../simple/ProjectCard'
import Pagination from './Pagination'

const ProjectsContainer = () => {
    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(ProjectServices.index)

  return (
	<div className="box_card_area pt-90 pb-80">
		<div className="container">
			<div className="row">
                {items?.items?.map((card, index) =>
                    <ProjectCard card={card} key={index}/>
                )}
			</div>
			<div className="row">
				<div className="col-md-12">
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
				</div>
			</div>
		</div>
	</div>
  )
}

export default ProjectsContainer