import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';
import useFetchItems from '../../hooks/useFetchItems';
import AppServices from '../../services/general/appServices';
import PageTop from '../../components/simple/PageTop';
import { useEffect } from 'react';
import About from '../../components/complex/About';
import About2 from '../../components/complex/About2';
import Advantages from '../../components/complex/Advantages';
import FeatureCards from '../../components/complex/FeatureCards';
import OurServices from '../../components/complex/OurServices';
import Workers from '../../components/complex/Workers';
import Packages from '../../components/complex/Packages';
import Reviews from '../../components/complex/Reviews';
import OurBlog from '../../components/complex/OurBlog';
import ContactForm from '../../components/complex/ContactForm';
import Partners from '../../components/complex/Partners';
import WhyChooseUs from '../../components/complex/WhyChooseUs';
import FaqComponent from '../../components/complex/FaqComponent';
import Preloader from '../../components/simple/Preloader';
import ProjectsComponent from '../../components/complex/ProjectsComponent';
import VideoComponent from '../../components/complex/VideoComponent';
import Gallery from '../../components/complex/Gallery';
import SampleSteps from '../../components/complex/SampleSteps';
import TextComponent from '../../components/complex/TextComponent';
import Steps2 from '../../components/complex/Steps2';
import TextImage from '../../components/simple/TextImage';

const SamplePage = () => {
    const {slug} = useParams();
    const {lang} = useLangContext();
    const navigate = useNavigate();
    const{lang: lang2} = useParams();

    useEffect(() => {
        if(lang){
            if(lang !== lang2){
                navigate(`/${lang}/page/${slug}`)
            }
        }
    }, [lang])

    const {
        items,
        loading,
    } = useFetchItems(AppServices.page, slug)

  return (
    <>{loading ?
        <Preloader/>
    :
        <div id={slug}>
            <PageTop item={items}/>
            {items.description && <div className="container mb-50 mt-50">
                <div dangerouslySetInnerHTML={{__html: items.description}}></div>
            </div>}
            {items?.elements?.map((elem, index) => 
                <div key={index}>
                    {
                    elem.type === "about" ?
                        <About elem={elem}/>
                    :
                    elem.type === "about-2" ?
                        <About2 elem={elem}/>
                    :
                    elem.type === "advantages" ?
                        <Advantages elem={elem}/>
                    :
                    elem.type === "advantages-2" ?
                        <FeatureCards elem={elem}/>
                    :
                    elem.type === "services-2" ?
                        <OurServices elem={elem}/>
                    :
                    elem.type === "workers" ?
                        <Workers elem={elem}/>
                    :
                    elem.type === "packages" ?
                        <Packages elem={elem}/>
                    :
                    elem.type === "reviews" ?
                        <Reviews elem={elem}/>
                    :
                    elem.type === "articles" ?
                        <OurBlog elem={elem}/>
                    :
                    elem.type === "form" ?
                        <ContactForm elem={elem}/>
                    :
                    elem.type === "partners" ?
                        <Partners elem={elem}/>
                    :
                    elem.type === "steps" ?
                        <SampleSteps elem={elem}/>
                    :
                    elem.type === "faq" ?
                        <FaqComponent elem={elem}/>
                    :
                    elem.type === "projects" ?
                        <ProjectsComponent elem={elem}/>
                    :
                    elem.type === "video-component" ?
                        <VideoComponent elem={elem}/>
                    :
                    elem.type === "text-component" ?
                        <TextComponent elem={elem}/>
                    :
                    elem.type === "gallery" ?
                        <Gallery elem={elem}/>
                    :
                    elem.type === "steps2" ?
                        <Steps2 elem={elem}/>
                    :
                    elem.type === "opening-3" ?
                        <TextImage elem={elem}/>
                    :
                    elem.type === "opening-4" ?
                        <div dangerouslySetInnerHTML={{__html: elem.description}}></div>
                    :
                        <></>
                    }
                </div>
            )}
        </div>
    }</>
  )
}

export default SamplePage