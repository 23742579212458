import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Fancybox from './FancyBox';
import img from "../../images/placeholder.png"

const VideoComponent = ({elem}) => {
    const options = {
        dots: false,
        nav: true,
        navText: ["<i class='fa fa-long-arrow-left''></i>", "<i class='fa fa-long-arrow-right''></i>"],
        margin:30,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            992: {
                items: 3
            },
            1200: {
                items: 3
            },
            1920: {
                items: 3
            }
        }
    }

  return (
	<div className="our-service projects-back" 
    style={{backgroundImage: `url(${elem.video_image})`}}
    >
		<div className="container">
			<div className="row">
				<div className="col-lg-12 wow fadeInRight" data-wow-delay="0.4s">
					<div className="section_title text_center white">
						{elem.subtitle && <div className="section_sub_title uppercase mb-3">
							<h6>{elem.subtitle}</h6>
						</div>}
						{elem.title && <div className="projects-title">
							<h1>{elem.title}</h1>
						</div>}
						<div className="em_bar">
							<div className="em_bar_bg"></div>
						</div>
					</div>
				</div>
			</div>
            <Fancybox
                    options={{
                        Carousel: {
                        infinite: false,
                        },
                    }}
                >
                <OwlCarousel className="videos-slider" {...options}>
                    {elem?.elements?.map((card, index) =>
                        <a href={card.url ? card.url : card.image} data-fancybox="gallery" className='col-md-6 col-xl-4' key={index}>
                            <div className='video-item'>
                                <img src={card.image} alt="" 
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; 
                                        currentTarget.src = img
                                    }}
                                />
                                {card.url && <span className='video-span'>
                                    <i className='fa fa-play'></i>
                                </span>}
                            </div>
                        </a>
                    )}
                </OwlCarousel>
            </Fancybox>
		</div>
	</div>
  )
}

export default VideoComponent