import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import img from "../../images/placeholder.png"

const FeatureCards = ({elem}) => {
    const options = {
        dots: false,
        nav: true,
        navText: ["<i class='fa fa-long-arrow-left''></i>", "<i class='fa fa-long-arrow-right''></i>"],
        margin:30,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            992: {
                items: 3
            },
            1200: {
                items: 3
            },
            1920: {
                items: 3
            }
        }
    }

  return (
	<div className="feature_card pt-35 pb-100" style={{backgroundImage: `url(${elem.steps_image})`}}>
		<div className="container">
            {elem.title &&<div className="row">
				<div className="col-lg-12 wow fadeInRight mb-50" data-wow-delay="0.4s">
					<div className="section_title text_center">
						{elem.subtitle && <div className="section_sub_title uppercase mb-3">
							<h6>{elem.subtitle}</h6>
						</div>}
						<div className="projects-title">
							<h4 className='theme-color'>{elem.title}</h4>
						</div>
						<div className="em_bar">
							<div className="em_bar_bg"></div>
						</div>
					</div>
				</div>
			</div>}
                <OwlCarousel className="videos-slider feautered-card" {...options}> 
                    {elem.elements?.map((card, index) =>
                            <div className="single_feature_card" key={index}>
                                {card.image && <div className="single_feature_six_seven_icon">
                                    <div className="icon">
                                        <img src={card.image} alt="" 
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; 
                                                currentTarget.src = img
                                            }}
                                        />
                                    </div>
                                </div>}
                                <div className="single_feature_card_content">
                                    <h4>{card.title}</h4>
                                    <p>{card.subtitle}</p>
                                </div>
                            </div>
                    )}
                </OwlCarousel>
		</div>
	</div>
  )
}

export default FeatureCards