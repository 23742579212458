import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import PartnersServices from '../../services/general/partnersServices'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import img from "../../images/placeholder.png"

const Partners = () => {
    const options = {
        loop: true,
        autoplay: true,
        autoplayTimeout: 10000,
        dots: true,
        nav: false,
        navText: ["<i class='fa fa-long-arrow-left''></i>", "<i class='fa fa-long-arrow-right''></i>"],
        responsive: {
            0: {
                items: 2
            },
			450: {
                items: 2
            },
            768: {
                items: 4
            },
            1200: {
                items: 4
            },
            1920: {
                items: 4
            }
        }
    }

    const {
        items,
        loading
    } = useFetchItems(PartnersServices.index)

  return (
    items?.items &&
	<div className="brand_area pt-35 pb-35">
		<div className="container">
			<div className="row">
				<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">	
					<div className="row">
						<OwlCarousel className="brand_list owl-carousel curosel-style" {...options}>
                            {items.items.map((card, index) =>
                                <div className="col-lg-12 wow flipInY" data-wow-delay="0ms" data-wow-duration="2500ms" key={index}>
                                    <div className="single_brand">
                                        <div className="single_brand_thumb">
                                            <img src={card.image} alt="logo-brand1" 
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; 
                                                currentTarget.src = img
                                            }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
						</OwlCarousel>
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default Partners