import React from 'react'
import { useState } from 'react'
import { NavLink } from 'react-router-dom';

const MenuItem = ({it, setClose}) => {
    const [open, setOpen] = useState(false);

  return (
    <li>
        <NavLink to={it.value} target={it.type} onClick={() => setClose(false)}>{it.title}</NavLink>
        {it.items &&
            <ul className={open ? "sub-menu" : "sub-menu sub-menu-none"}>
                {it.items?.map((el, index) =>
                    <li key={index}><NavLink to={el.value} target={el.type} onClick={() => setClose(false)}>{el.title}</NavLink></li>
                )}
            </ul>
        }
        {it.items && <div className="mean-expand" onClick={() => setOpen(!open)}>{open ? '-' : '+' }</div>}
    </li>
  )
}

export default MenuItem