import React from 'react'
import { NavLink } from 'react-router-dom'
import { useLangContext } from '../../i18n/ProvideLang'
import img from "../../images/placeholder.png"

const ServiceCard = ({card}) => {
    const {lang, localizationsItems} = useLangContext();
  return (
    <div className="whychoose_flipbox mb-30">
        <div className="whychoose_flipbox_font">
            <div className="whychoose_flipbox_inner">
                <div className="whychoose_flipbox_icon">
                    <div className="icon">
                        <img src={card.icon ? card.icon : img} alt=""
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; 
                                currentTarget.src = img
                            }}
                        />
                    </div>
                </div>
                <div className="service_box_title">
                    <h3>{card.title}</h3>
                </div>
            </div>
        </div>
        <div className="whychoose_flipbox_back"
            style={{backgroundImage: `url(${card.preview_image})`}}
            >
            <div className="whychoose_flipbox_inner">
                <div className="flipbox_title">
                    <h3>{card.title}</h3>
                </div>
                <div className="flipbox_desc"
                    dangerouslySetInnerHTML={{__html: card.description}}>
                </div>
                <div className="flipbox_button">
                    <NavLink to={`/${lang}/services/${card.slug}`}>{localizationsItems?.detail}<i className="fa fa-angle-double-right"></i></NavLink>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ServiceCard