import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import ProjectServices from '../../services/general/projectServices'
import { useLangContext } from '../../i18n/ProvideLang'
import { NavLink, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';

const ProjectsComponent = ({elem}) => {
    const {
        items,
    } = useFetchItems(ProjectServices.index)

    const [activeSlide, setActiveSlide] = useState(0);
    const [slideLength, setSlideLength] = useState()
    const [onMouse, setOnMouse] = useState(false);
    const [timeOut, setTimeOut] = useState();

    useEffect(() => {
        if(items?.items){
            setSlideLength(items.items.length)
        }
    }, [items])

    const {lang, localizationsItems} = useLangContext();
    const navigate = useNavigate();

    useEffect(() => {
        if(!onMouse){
            setTimeOut(setTimeout(slideTimeout, 3000));
        }
    }, [activeSlide, onMouse])

    const setPrev = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if(activeSlide <= 0){
            setActiveSlide(slideLength - 1)
        }else{
            setActiveSlide(activeSlide - 1)
        }
    }

    const slideTimeout = () => {
        if(activeSlide + 1 >= slideLength){
            setActiveSlide(0)
        }else{
            setActiveSlide(activeSlide + 1)
        }
    }

    const setNext = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if(activeSlide + 1 >= slideLength){
            setActiveSlide(0)
        }else{
            setActiveSlide(activeSlide + 1)
        }
    }

    const handleEnter = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setOnMouse(true);
        clearTimeout(timeOut);
    }

    const handleLeave = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setOnMouse(false);
    }
    
  return (
    <div className='projects-container' onMouseEnter={e => handleEnter(e)} onMouseLeave={e => handleLeave(e)}>
        <div className="container">
			<div className="row">
				<div className="col-lg-12 wow fadeInRight mb-50" data-wow-delay="0.4s">
					<div className="section_title text_center white">
						{elem.subtitle && <div className="section_sub_title uppercase mb-3">
							<h6>{elem.subtitle}</h6>
						</div>}
						{elem.title && <div className="projects-title">
							<h1>{elem.title}</h1>
						</div>}
						<div className="em_bar">
							<div className="em_bar_bg"></div>
						</div>
					</div>
				</div>
			</div>
        </div>
        {items &&
        <div className='slider-wrapper'>
            <div className='slider-main' style={{left:`-${activeSlide * 100}%`}}>
                {items.items?.map((card, index) =>
                    <div className="slider_area d-flex align-items-center slider-project" key={index} style={{backgroundImage: `url(${card.preview_image})`}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="single_slider">
                                        <NavLink to={`/${lang}/projects/${card.slug}`}>
                                            <div className="slider_content">
                                                <div className="slider_text">
                                                    <div className="slider_text_inner">
                                                        <h2>{card.title}</h2>
                                                        <h6>{card.subtitle}</h6>
                                                        <button className="white-button" onClick={() => navigate(`/${lang}/projects/${card.slug}`)}>
                                                            {localizationsItems?.detail}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className='slider-nav'>
                    <button className='slider-nav-button slider-nav-prev' onClick={e => setPrev(e)}>
                        <i className='fa fa-long-arrow-left'></i>
                    </button>
                    <button className='slider-nav-button slider-nav-next' onClick={e => setNext(e)}>
                        <i className='fa fa-long-arrow-right'></i>
                    </button>
                </div>
        </div>}
    </div>
  )
}

export default ProjectsComponent