import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import img from "../../images/placeholder.png"

const Reviews = ({elem}) => {
    const options = {
        loop: true,
        autoplay: true,
        autoplayTimeout: 10000,
        dots: true,
        nav: false,
        navText: ["<i class='fa fa-long-arrow-left''></i>", "<i class='fa fa-long-arrow-right''></i>"],
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            992: {
                items: 3
            },
            1000: {
                items: 3
            },
            1920: {
                items: 3
            }
        }
    }

  return (
	<div className="testimonial-area slider5 pt-100 pb-100" style={{backgroundImage: `url(${elem.review_image})`}}>
		<div className="container">
			<div className="row">
				<div className="col-lg-12 wow fadeInRight" data-wow-delay="0.4s">
					<div className="section_title text_center white mb-55">
						<div className="section_sub_title uppercase mb-3">
							<h6>{elem.subtitle}</h6>
						</div>
						<div className="section_main_title">
							<h1>{elem.title}</h1>
						</div>
						<div className="em_bar">
							<div className="em_bar_bg"></div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
					<div className="row">
						<OwlCarousel className="testimonial_list owl-carousel curosel-style" {...options}>
                            {elem.elements?.map((card, index) =>
                                <div className="col-lg-12 wow flipInY" data-wow-delay="0ms" data-wow-duration="2500ms" key={index}>
                                    <div className="testimonial">
                                        <div className="testimonial-content"
                                            dangerouslySetInnerHTML={{__html:card.description}}>
                                        </div>
                                        <div className="testimonial-profile">
                                            <div className="pic">
                                                <img src={card.image} alt="testimonial1"
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null; 
                                                    currentTarget.src = img
                                                }}
                                                />
                                            </div>
                                            <h3 className="title1">
                                                {card.title}
                                                <span className="post1">{card.subtitle}</span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            )}
						</OwlCarousel>
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default Reviews