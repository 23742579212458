import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import ServiceServices from '../../services/general/serviceServices'
import { NavLink } from 'react-router-dom'
import { useLangContext } from '../../i18n/ProvideLang'

const ServiceSidebar = () => {
    const {lang, localizationsItems} = useLangContext();

    const {
        items,
    } = useFetchItems(ServiceServices.index)

  return (
    <div className="col-lg-4 col-md-4">
        <div className="sidebar-right wow fadeInRight" data-wow-delay="0.4s">

            <aside className="widget widget-service-list">
                <div className="widget-title">
                    <h5>{localizationsItems?.all_services}</h5>
                </div>
                <div className="all-service-list">
                    <ul>
                        {items?.items?.slice(0,8).map((card, index) =>
                            <li key={index}><NavLink to={`/${lang}/services/${card.slug}`}>{card.title} <span><i className="fa fa-angle-right"></i></span></NavLink></li>
                        )}
                    </ul>
                </div>
            </aside>
        </div>
    </div>
  )
}

export default ServiceSidebar