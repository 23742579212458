import React from 'react'
import img from "../../images/placeholder.png"

const Advantages = ({elem}) => {
  return (
	<div className="best_counter white bg_before bg_fixed pt-100 pb-100"
        style={{backgroundImage: `url(${elem.steps_image})`}}>
		<div className="container">
			<div className="row">
				<div className="col-lg-12 wow fadeInRight" data-wow-delay="0.4s">
					<div className="section_title text_center white mb-50 mt-3">
						<div className="section_main_title">
							<h1>{elem.title}</h1>
						</div>
						<div className="em_bar">
							<div className="em_bar_bg"></div>
						</div>
					</div>

				</div>
			</div>
			<div className="row">
                {elem.elements?.map((card, index) =>
                    <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.4s" key={index}>
                        <div className="single_counter text_center mb-4">
                            <div className="single_counter_icon pb-3">
                                <i>
                                    <img src={card.image} alt=""
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; 
                                            currentTarget.src = img
                                        }}
                                    />
                                </i>
                            </div>
                            <div className="countr_text">
                                <h1><span className="counter">{card.counter}</span><span>{card.subtitle}</span> </h1>
                            </div>
                            <div className="counter_desc">
                                <h5>{card.title}</h5>
                            </div>
                        </div>
                    </div>
                )}
			</div>
		</div>
	</div>
  )
}

export default Advantages