import React from 'react'
import ArticleCard from '../simple/ArticleCard'
import useFetchItems from '../../hooks/useFetchItems'
import ArticleServices from '../../services/general/articleServices'
import Pagination from './Pagination'
import ArticleSidebar from './ArticleSidebar'

const ArticlesContainer = () => {
    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(ArticleServices.index)
    
  return (
    <div className="blog_area blog-grid left-sidebar pt-90 pb-80" id="blog">
        <div className="container">		
            <div className="row">	
                <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12">
                    <div className="articles-grid">	
                        {items?.items?.map((card, index) => 
                            <div className="articles-grid-item" key={index}>	
                                <ArticleCard card={card}/>
                            </div>
                        )}
                    </div>
                            
                    <div className="row">
                        <div className="col-md-12">
                            <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                        </div>
                    </div>	
                </div>
                <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12 sidebar-right content-widget pdsr">
                    <ArticleSidebar/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ArticlesContainer