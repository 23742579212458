import React from 'react'
import { NavLink } from 'react-router-dom'
import img from "../../images/placeholder.png"

const ProjectsList = ({elem}) => {

  return (
    <div className='row'>
        <div className="col-sm-12 col-md-8">
            <div className='project-list-image'>
                <img src={elem.steps_image} alt="" 
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src = img
                }}
                />
            </div>
        </div>
        <div className="col-sm-12 col-md-4">
            <div className='project-list-title'>{elem.title}</div>
            <ul className='project-list'>
                {elem.elements?.map((card, index) =>
                    <li key={index}>
                        <NavLink to={card.link}>{card.title}</NavLink>
                    </li>
                )}
            </ul>
        </div>
    </div>
  )
}

export default ProjectsList