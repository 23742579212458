import React, { useEffect, useState } from 'react'
import { useLangContext } from '../../i18n/ProvideLang';
import img from "../../images/placeholder.png"
import ApplicationModal from '../modals/ApplicationModal';

const About2 = ({elem}) => {
    const [banner, setBanner] = useState();
    const [banner2, setBanner2] = useState();
    const [show, setShow] = useState();

    const {lang, localizationsItems} = useLangContext();

    useEffect(() => {
        if(elem){
            setBanner(elem.elements?.find(it => it.type === "images")?.elements[0])
            setBanner2(elem.elements?.find(it => it.type === "images")?.elements[1])
        }
    }, [elem])

  return (
	<div className="about_area pt-100 pb-80" style={{backgroundImage: `url(${banner})`}}>
		<div className="container">
			<div className="row">
				<div className="col-lg-6 col-md-6 col-sm-12 col-xs-6 wow fadeInLeft" data-wow-delay="0.4s">
					<div className="section_title text_left mb-40 mt-3">
						<div className="section_sub_title uppercase mb-3">
							<h6>{elem.subtitle}</h6>
						</div>
						<div className="section_main_title">
							<h1 dangerouslySetInnerHTML={{__html: elem.title}}></h1>
						</div>
						<div className="em_bar">
							<div className="em_bar_bg"></div>
						</div>
						<div className="section_content_text bold pt-5"
                            dangerouslySetInnerHTML={{__html: elem.description}}
                        ></div>
					</div>
					<div className="singel_about_left mb-30">
						<div className="singel_about_left_inner pl-4">
							<div className="button button-two" onClick={() => setShow(true)}>
								{localizationsItems?.send_form}
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-6 col-md-6 col-sm-12 col-xs-6 wow fadeInRight" data-wow-delay="0.4s">
					<div className="single_about_thumb mb-3">
						<div className="single_about_thumb_inner bounce-animate">
							<img src={banner2} alt="about5" 
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img
                                }}
                            />
						</div>
					</div>
				</div>
			</div>
		</div>
        {show && <ApplicationModal show={show} setShow={setShow}/>}
	</div>
  )
}

export default About2