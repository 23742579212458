import React from 'react'
import { useLangContext } from '../../i18n/ProvideLang';
import { NavLink } from 'react-router-dom';
import img from "../../images/placeholder.png"

const EventCard = ({card}) => {
    const {lang} = useLangContext();

  return (
    <div className="col-md-4 col-sm-6 wow flipInY" data-wow-delay="0ms" data-wow-duration="2500ms">
        <div className="box">
            <img src={card.image ? card.image : img} alt="case1"
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src = img
                }}
            />
            <div className="box-content">
                <h3 className="title3">{card.title}</h3>
                <span className="post3">{card.subtitle}</span>
            </div>
            <ul className="icon">
                {card.link ? 
                    <li><NavLink to={card.link}><i className="fa fa-long-arrow-right"></i></NavLink></li>
                    : 
                    <li><NavLink to={`/${lang}/events/${card.slug}`}><i className="fa fa-long-arrow-right"></i></NavLink></li>
                }
            </ul>
        </div>
    </div>
  )
}

export default EventCard