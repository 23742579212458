import http from "./http-common"
import {getData} from "../handlers";

export default class MediaServices {
    static index = (queryString) => {
        return getData(http, `/app/media${queryString ? `?${queryString}` : ""}`);
    }

    static view = (slug, queryString) => {
        return getData(http, `/app/media/${slug}${queryString ? `${queryString}` : ""}`);
    }
    
    static categories = (queryString) => {
        return getData(http, `/app/media/categories${queryString ? `${queryString}` : ""}`);
    }
}