import ArticleDetail from "../pages/articles/ArticleDetail";
import ArticlesPage from "../pages/articles/ArticlesPage";
import EventsDetail from "../pages/events/EventsDetail";
import EventsPage from "../pages/events/EventsPage";
import MainPage from "../pages/main/MainPage";
import MediaDetail from "../pages/media/MediaDetail";
import MediaPage from "../pages/media/MediaPage";
import ProjectDetail from "../pages/projects/ProjectDetail";
import ProjectsPage from "../pages/projects/ProjectsPage";
import SamplePage from "../pages/sample/SamplePage";
import ServicesDetail from "../pages/services/ServicesDetail";
import ServicesPage from "../pages/services/ServicesPage";
import { ARTICLE_DETAIL_PAGE_ROUTE, ARTICLE_PAGE_ROUTE, EVENTS_PAGE_ROUTE, EVENT_DETAIL_PAGE_ROUTE, MAIN_PAGE_ROUTE, MEDIA_DETAIL_PAGE_ROUTE, MEDIA_PAGE_ROUTE, PROJECTS_DETAIL_PAGE_ROUTE, PROJECTS_PAGE_ROUTE, SAMPLE_PAGE_SOUTE, SERVICES_DETAIL_PAGE_ROUTE, SERVICES_PAGE_ROUTE } from "./Constants";

export const publicRoutes = [
    {
        path: '/',
        Component: MainPage
    },
    {
        path: MAIN_PAGE_ROUTE,
        Component: MainPage
    },
    {
        path: SERVICES_PAGE_ROUTE,
        Component: ServicesPage
    },
    {
        path: SERVICES_DETAIL_PAGE_ROUTE,
        Component: ServicesDetail
    },
    {
        path: ARTICLE_PAGE_ROUTE,
        Component: ArticlesPage
    },
    {
        path: ARTICLE_DETAIL_PAGE_ROUTE,
        Component: ArticleDetail
    },
    {
        path: PROJECTS_PAGE_ROUTE,
        Component: ProjectsPage
    },
    {
        path: PROJECTS_DETAIL_PAGE_ROUTE,
        Component: ProjectDetail
    },
    {
        path: EVENTS_PAGE_ROUTE,
        Component: EventsPage
    },
    {
        path: EVENT_DETAIL_PAGE_ROUTE,
        Component: EventsDetail
    },
    {
        path: SAMPLE_PAGE_SOUTE,
        Component: SamplePage
    },
    {
        path: MEDIA_PAGE_ROUTE,
        Component: MediaPage
    },
    {
        path: MEDIA_DETAIL_PAGE_ROUTE,
        Component: MediaDetail
    },
]