import React from 'react'

const ProjectSteps = ({elem}) => {
  return (
    <div className='projects-steps'>
        <div dangerouslySetInnerHTML={{__html: elem.description}}></div>
        <div className='steps-grid'>
            {elem.elements?.map((card, index) =>
                <div key={index} className='project-step-card'>
                    <h4>{card.title}</h4>
                    <div dangerouslySetInnerHTML={{__html: card.description}}></div>
                </div>
            )}
        </div>
    </div>
  )
}

export default ProjectSteps