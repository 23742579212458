import React from 'react'
import img from "../../images/placeholder.png"

const SampleSteps = ({elem}) => {
  return (
	<div className="main_timeline_work d-flex align-items-center pt-80 pb-80">
		<div className="container">
			<div className="row">
				<div className="col-lg-12 wow fadeInRight" data-wow-delay="0.4s">
					<div className="section_title text_center mb-50 mt-3">

						<div className="section_sub_title uppercase mb-3">
							<h6>{elem.subtitle}</h6>
						</div>
						<div className="section_main_title">
							<h1>{elem.title}</h1>
						</div>
						<div className="em_bar">
							<div className="em_bar_bg"></div>
						</div>

					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-12">
					<div className="main-timeline">
                        {elem.elements?.map((card, index) =>
                            <div className="timeline wow fadeInRight" key={index}>
                                <div className="timeline-content">
                                    <span className="icon">
                                        <img src={card.image} alt="" 
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; 
                                            currentTarget.src = img
                                        }}
                                        />
                                    </span>
                                    <div className="inner-content">
                                        <h3 className="title5">{card.title}</h3>
                                        <p className="description5" dangerouslySetInnerHTML={{__html: card.description}}></p>
                                    </div>
                                </div>
                            </div>
                        )}
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default SampleSteps