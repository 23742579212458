import React from 'react'
import { NavLink } from 'react-router-dom'
import { useLangContext } from '../../i18n/ProvideLang'
import img from "../../images/placeholder.png"

const ProjectCard = ({card}) => {
    const {lang} = useLangContext();
    
  return (
    <div className="col-md-4 col-sm-6 wow flipInY" data-wow-delay="0ms" data-wow-duration="2500ms">
        <div className="box">
            <img src={card.image} alt="case1"
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src = img
                }}
            />
            <div className="box-content">
                <h3 className="title3">{card.title}</h3>
                <span className="post3">{card.subtitle}</span>
            </div>
            <ul className="icon">
                <li><NavLink to={`/${lang}/projects/${card.slug}`}><i className="fa fa-long-arrow-right"></i></NavLink></li>
            </ul>
        </div>
    </div>
  )
}

export default ProjectCard