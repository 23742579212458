import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import img from "../../images/placeholder.png"
import { NavLink } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';
import useFetchItems from '../../hooks/useFetchItems';
import MediaServices from '../../services/general/mediaServices';

const Media = ({elem}) => {
    const {lang} = useLangContext();

    const {
        items,
    } = useFetchItems(MediaServices.index)

    const options = {
        dots: false,
        nav: true,
        navText: ["<i class='fa fa-long-arrow-left''></i>", "<i class='fa fa-long-arrow-right''></i>"],
        margin:30,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            992: {
                items: 3
            },
            1200: {
                items: 3
            },
            1920: {
                items: 3
            }
        }
    }

  return (
	<div className="our-service projects-back" >
		<div className="container">
			<div className="row">
				<div className="col-lg-12 wow fadeInRight mb-40" data-wow-delay="0.4s">
					<div className="section_title text_center white">
						<div className="projects-title">
							<h1>{elem.title}</h1>
						</div>
						<div className="em_bar">
							<div className="em_bar_bg"></div>
						</div>
					</div>
				</div>
			</div>
            {items?.items?.length >= 1 ?
                <OwlCarousel className="videos-slider media-container" {...options}>
                    {items?.items?.map((card, index) => 
                        <NavLink className='media-item' to={`/${lang}/media/${card.slug}`} key={index}>
                            <img src={card.preview_image ? card.preview_image : img} alt="" 
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img
                                }}
                            />
                            <div className='media-img'></div>
                            <h4>{card.title}</h4>
                        </NavLink>
                    )}
                </OwlCarousel>
                :
                <></>
            }
		</div>
	</div>
  )
}

export default Media