import React from 'react'
import About from './About'
import About2 from './About2'
import FeatureCards from './FeatureCards'
import OurServices from './OurServices'
import Workers from './Workers'
import Packages from './Packages'
import Reviews from './Reviews'
import OurBlog from './OurBlog'
import ContactForm from './ContactForm'
import Partners from './Partners'
import FaqComponent from './FaqComponent'
import ProjectsComponent from './ProjectsComponent'
import VideoComponent from './VideoComponent'
import Gallery from './Gallery'
import ProjectsList from './ProjectsList'
import TextComponent from './TextComponent'
import TextImage from '../simple/TextImage'
import ProjectSteps from '../simple/ProjectSteps'

const ProjectDetailContainer = ({item}) => {
  return (
    <div className="blog_area blog-details-area pt-100 pb-100" id="blog">
        <div className="container">		
            <div className="row">	
                <div className="col-12">
                    <div className="row">
                        <div className="col-md-12">
                            {/*<div className="blog_details">
                                <div className="blog_dtl_content">
                                    {/*<h2>{item.title}</h2>
                                    <div dangerouslySetInnerHTML={{__html: item.description}}></div>
                                    <ShareBlock/>
                                </div>
                            </div>*/}
                            {item?.elements?.map((elem, index) => 
                                <div key={index}>
                                    {
                                    elem.type === "about" ?
                                        <About elem={elem}/>
                                    :
                                    elem.type === "about-2" ?
                                        <About2 elem={elem}/>
                                    :
                                    elem.type === "advantages" ?
                                        <ProjectsList elem={elem}/>
                                    :
                                    elem.type === "advantages-2" ?
                                        <FeatureCards elem={elem}/>
                                    :
                                    elem.type === "services-2" ?
                                        <OurServices elem={elem}/>
                                    :
                                    elem.type === "workers" ?
                                        <Workers elem={elem}/>
                                    :
                                    elem.type === "packages" ?
                                        <Packages elem={elem}/>
                                    :
                                    elem.type === "reviews" ?
                                        <Reviews elem={elem}/>
                                    :
                                    elem.type === "articles" ?
                                        <OurBlog elem={elem}/>
                                    :
                                    elem.type === "form" ?
                                        <ContactForm elem={elem}/>
                                    :
                                    elem.type === "partners" ?
                                        <Partners elem={elem}/>
                                    :
                                    elem.type === "steps" ?
                                        <ProjectSteps elem={elem}/>
                                    :
                                    elem.type === "faq" ?
                                        <FaqComponent elem={elem}/>
                                    :
                                    elem.type === "projects" ?
                                        <ProjectsComponent elem={elem}/>
                                    :
                                    elem.type === "video-component" ?
                                        <VideoComponent elem={elem}/>
                                    :
                                    elem.type === "gallery" ?
                                        <Gallery elem={elem}/>
                                    :
                                    elem.type === "text-component" ?
                                        <TextComponent elem={elem}/>
                                    :
                                    elem.type === "opening-3" ?
                                        <TextImage elem={elem}/>
                                    :
                                        <></>
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ProjectDetailContainer