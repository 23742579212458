import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import Utils from '../../services/utils';
import AppServices from '../../services/general/appServices';
import img from "../../images/placeholder.png"
import { useLangContext } from '../../i18n/ProvideLang';

const ContactForm = ({elem}) => {
    const {localizationsItems} = useLangContext();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [success, setSuccess] = useState();

    const {handleSubmit, register, reset, control, formState: {errors}} = useForm();

    const CustomSubmit = async (data) => {
        setLoading(true);

        let newData = {
            fields: {
                ...data
            }
        }

        let query = new URLSearchParams(window.location.search);

        if(query.toString()){
            query.get('utm_source') && (newData.utm_source = query.get('utm_source'));
            query.get('utm_medium') && (newData.utm_medium = query.get('utm_medium'))
            query.get('utm_campaign') && (newData.utm_campaign = query.get('utm_campaign'))
            query.get('utm_content') && (newData.utm_content = query.get('utm_content'))
            query.get('utm_term') && (newData.utm_term = query.get('utm_term'))
        }else{
            Utils.getCookie('utm_source') && newData.append('utm_source', Utils.getCookie('utm_source'));
            Utils.getCookie('utm_medium') && newData.append('utm_medium', Utils.getCookie('utm_medium'));
            Utils.getCookie('utm_campaign') && newData.append('utm_campaign', Utils.getCookie('utm_campaign'));
            Utils.getCookie('utm_content') && newData.append('utm_content', Utils.getCookie('utm_content'));
            Utils.getCookie('utm_term') && newData.append('utm_term', Utils.getCookie('utm_term'));
        }

        const res = await AppServices.requestTemplate(newData);

        if(res.statusCode === 200){
            setSuccess(true)
            setVisible(true)
            setTimeout(() => {
                setVisible(false)
                reset();
            }, 3000);
        }else{
            setSuccess(false)
        }
        setLoading(false)
    }

  return (
	<div className="contact_sm_area pt-80 pb-80">
		<div className="container">
			<div className="row">
				<div className="col-lg-12 wow fadeInRight" data-wow-delay="0.4s">
					<div className="section_title text_center mb-50 mt-3">
						<div className="section_sub_title uppercase mb-3">
							<h6>{elem.subtitle}</h6>
						</div>
						<div className="section_main_title">
							<h1>{elem.title}</h1>
						</div>
						<div className="em_bar">
							<div className="em_bar_bg"></div>
						</div>
					</div>
				</div>
			</div>
			<div className="row cnt_box align-items-center">
				<div className="col-lg-6 p-0">
					<div className="career_work_thumb">
						<img src={elem.form_image} alt="contact" 
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; 
                                currentTarget.src = img
                            }}
                        />
					</div>
				</div>
				<div className="col-xl-6">
					<div className="contact_sm_title pb-3">
						<h2>{elem.title}</h2>
					</div>
					<div className="quote_wrapper">
						<form onSubmit={handleSubmit(CustomSubmit)}>
							<div className="row">
                                {elem.elements?.map((card, index) => 
                                    <div className="col-lg-12" key={index}>
                                        <div className="form_box mb-20">
                                            <input type="text" {...register(card.name)} placeholder={card.placeholder}/>
                                        </div>
                                    </div>
                                )}
								<div className="col-lg-12">
									<div className="button button-two">
										{localizationsItems?.send_form}
									</div>

								</div>
							</div>
						</form>
						<p className="form-message"></p>
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default ContactForm