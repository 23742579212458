import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'

const TextImage = ({elem}) => {
    const [banner, setBanner] = useState();
    
    useEffect(() => {
        if(elem){
            setBanner(elem.elements?.find(it => it.type === "banner_image")?.image)
        }
    }, [elem])

  return (
    <div className="blog_area blog-details-area" id="blog">
        <div className="container">
            {elem.subtitle && <div className='text-sub-title'>{elem.subtitle}</div>}
            <div className={elem.subtitle ? 'text-image text-image2' : 'text-image'} style={{backgroundImage: `url(${banner})`}}>
                <div className='text-wrapper'>
                    <div className='title'>{elem.title}</div>
                    <div className='description' dangerouslySetInnerHTML={{__html: elem.description}}></div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TextImage