import React, { useEffect, useState } from 'react'
import useFetchItems from '../../hooks/useFetchItems';
import AppServices from '../../services/general/appServices';
import { NavLink } from 'react-router-dom';
import FooterArticles from '../simple/FooterArticles';
import { useLangContext } from '../../i18n/ProvideLang';
import img from "../../images/placeholder.png"

const Footer = ({item}) => {
    const [socials, setSocials] = useState();
    const [logo, setLogo] = useState();
    const [text, setText] = useState();
    const [email, setEmail] = useState();
    const [address, setAddress] = useState();
    const [phone, setPhone] = useState();
    const [workTime, setWorkTime] = useState();
    const {lang, localizationsItems} = useLangContext();

    useEffect(() => {
        if(item){
            setSocials(item.find(it => it.type === "socials")?.value);
            setLogo(item.find(it => it.type === "white-logo")?.value);
            setText(item.find(it => it.type === "footer_text")?.value);
            setEmail(item.find(it => it.type === "email")?.value);
            setAddress(item.find(it => it.type === "address")?.value);
            setPhone(item.find(it => it.type === "phone")?.value);
            setWorkTime(item.find(it => it.type === "work_time")?.value);
        }
    }, [item])

    const {
        items,
    } = useFetchItems(AppServices.menu, 'bottom-menu')

  return (
	<div className="footer-toparea slider6 pt-100">
		<div className="container">
			<div className="row">
				<div className="col-lg-3 col-md-6 col-sm-12">
					<div className="widget widgets-company-info">
						<div className="footer-bottom-logo pb-40">
							<img src={logo} alt="logo-digicrew" 
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img
                                }}
                            />
						</div>
						{text && <div className="company-info-demo">
                            {text.map((t, index) =>
                                <p key={index}>{t} </p>
                            )}
						</div>}
						{socials && <div className="social-company-info pt-3">
							<div className="social-company-text mr-3">
								<p>{localizationsItems?.follow_us}:</p>
							</div>
							<div className="social-company-icon">
                                {socials.map((s, index) =>
								    <a href={s.value} target='blank' key={index}>
                                        <i className={
                                            s.type === "facebook" ? "fa fa-facebook" :
                                            s.type === "twitter" ? "fa fa-twitter" :
                                            s.type === "linkedin" ? "fa fa-linkedin" :
                                            s.type === "skype" ? "fa fa-skype" :
                                            s.type === "instagram" ? "fa fa-instagram" :
                                            s.type === "youtube" ? "fa fa-youtube-play" :
                                            "fa fa-facebook"
                                        }></i>
                                    </a>
                                )}
							</div>
						</div>}
					</div>
				</div>
				<div className="col-lg-3 col-md-6 col-sm-12">
					<div className="widget widget-nav-menu">
						<h4 className="widget-title pb-4">{localizationsItems?.quick_links}</h4>
						<div className="menu-quick-link-container ml-4">
							<ul id="menu-quick-link" className="menu">
                                {items?.map((it, index) =>
                                	<li key={index}>
                                        <NavLink to={it.value} target={it.type}>{it.title}</NavLink>
                                    </li>
                                )}
							</ul>
						</div>
					</div>
				</div>
				<div className="col-lg-3 col-md-6 col-sm-12">
					<div className="widget widgets-company-info">
						<h3 className="widget-title pb-4">{localizationsItems?.footer_address}</h3>
						{workTime && <div className="company-info-demo">
							{workTime.map((wt, index) =>
                                <p key={index}>{wt}</p>  
                            )}
						</div>}
                        {address &&
						<div className="footer-social-info">
							<p><span>{localizationsItems?.address}: </span> 
                                {address.map((ad, index) =>
                                    <i key={index}>{ad}</i>
                                )}
                            </p>
						</div>}
                        {phone &&
						<div className="footer-social-info">
							<p><span>{localizationsItems?.phone}: </span>
                                {phone.map((ph, index) =>
                                    <i key={index}>{[ph]}</i>
                                )}
							</p>
						</div>}
                        {email &&
						<div className="footer-social-info">
							<p><span>{localizationsItems?.email}: </span>
                                {email.map((em, index) =>
                                    <i key={index}>{em}</i>
                                )}
                            </p>
						</div>}

					</div>
				</div>
                <FooterArticles/>
			</div>
			<div className="row footer-copyright mt-70 pt-3 pb-1">
				<div className="col-lg-6 col-md-6">
					<div className="footer-copyright-content">
						<div className="footer-copyright-content-copy">
							<p>© Panama.kz</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default Footer