import React from 'react'
import ServiceSidebar from '../simple/ServiceSidebar'

const ServiceDetailContainer = ({item}) => {
  return (
	<div className="Services Details pt-100 pb-100">
		<div className="container">
            <div className="row">
                <div className="col-lg-8 col-md-8">
                    <div className="service-details-wrap wow fadeInLeft" data-wow-delay="0.4s">
                        <div className="services-detail-content mb-30"><br/>
                            <h4>{item.title}</h4>
                            <div dangerouslySetInnerHTML={{__html: item.description}}></div>
                        </div>
                    </div>
                </div>
                <ServiceSidebar/>
            </div>
		</div>
	</div>
  )
}

export default ServiceDetailContainer