import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import ArticleServices from '../../services/general/articleServices'
import { NavLink } from 'react-router-dom'
import { useLangContext } from '../../i18n/ProvideLang'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import img from "../../images/placeholder.png"

const OurBlog = ({elem}) => {
    const {lang, localizationsItems} = useLangContext();

    const options = {
        dots: false,
        nav: true,
        navText: ["<i class='fa fa-long-arrow-left''></i>", "<i class='fa fa-long-arrow-right''></i>"],
        margin:30,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            992: {
                items: 3
            },
            1200: {
                items: 3
            },
            1920: {
                items: 3
            }
        }
    }

    const {
        items,
    } = useFetchItems(ArticleServices.index)

  return (
	<div className="blog_post pt-70 pb-80 projects-back" style={{backgroundImage: `url(${elem.news_image})`}}>
		<div className="container">
			<div className="row">
				<div className="col-lg-9">
					<div className="section_title text_left mb-60 mt-3">
						<div className="section_sub_title uppercase mb-3">
							<h6>{elem.subtitle}</h6>
						</div>
						<div className="section_main_title">
							<h1>{elem.title}</h1>
						</div>
						<div className="em_bar">
							<div className="em_bar_bg"></div>
						</div>

					</div>
				</div>
				<div className="col-lg-3">
					<div className="section_button mt-50">
						<div className="button two">
							<NavLink to={`/${lang}/articles`}>{localizationsItems?.all_blogs}</NavLink>
						</div>
					</div>
				</div>
			</div>
            {items?.items &&
            <OwlCarousel className="videos-slider" {...options}>
                {items.items.map((card, index) =>
                    <NavLink to={`/${lang}/articles/${card.slug}`} key={index}>
                            <div className="post-slide" >
                                <div className="post-img">
                                    <img src={card.image} alt="blog1"
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; 
                                            currentTarget.src = img
                                        }}
                                    />
                                </div>
                                <div className="post-review">
                                    <div className="blog_details">
                                        {card.parent && <span className='pr-3'>{card.parent.name}</span>}
                                        <span className="meta-date">{card.date}</span>
                                    </div><br/>
                                    <h3 className="post-title">{card.title}</h3>
                                    <p className="post-description" dangerouslySetInnerHTML={{__html: card.description}}></p>
                                    <div className="button two">
                                        {localizationsItems?.read_more}
                                    </div>
                                </div>
                        </div>
                    </NavLink>
                )}
            </OwlCarousel>}
		</div>
	</div>
  )
}

export default OurBlog