import React, { useEffect } from 'react'
import PageTop from '../../components/simple/PageTop'
import { useNavigate, useParams } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';
import useFetchInfo from '../../hooks/useFetchInfo.hook';
import ArticleServices from '../../services/general/articleServices';
import ArticleDetailContainer from '../../components/complex/ArticleDetailContainer';
import Preloader from '../../components/simple/Preloader';
import About from '../../components/complex/About';
import About2 from '../../components/complex/About2';
import Advantages from '../../components/complex/Advantages';
import FeatureCards from '../../components/complex/FeatureCards';
import OurServices from '../../components/complex/OurServices';
import Workers from '../../components/complex/Workers';
import Packages from '../../components/complex/Packages';
import Reviews from '../../components/complex/Reviews';
import OurBlog from '../../components/complex/OurBlog';
import ContactForm from '../../components/complex/ContactForm';
import Partners from '../../components/complex/Partners';
import WhyChooseUs from '../../components/complex/WhyChooseUs';
import FaqComponent from '../../components/complex/FaqComponent';
import ProjectsComponent from '../../components/complex/ProjectsComponent';
import VideoComponent from '../../components/complex/VideoComponent';
import Gallery from '../../components/complex/Gallery';

const ArticleDetail = () => {
    const {slug} = useParams();
    const {lang, localizationsItems} = useLangContext();
    const navigate = useNavigate();
    const {lang: lang2} = useParams();

    const {info, loading} = useFetchInfo(ArticleServices.view, slug);

    useEffect(() => {
        if(lang){
            if(lang !== lang2) {
                navigate(`/${lang}/articles/${slug}`)
            }
        }
    }, [lang])

  return (
    <>{loading ?
        <Preloader/>
    :
    info &&
        <div>
            <PageTop item={info} parentLink={`/${lang}/articles`} parentName={'Статьи'}/>
            <ArticleDetailContainer item={info}/>
            <div>
            {info?.elements?.map((elem, index) => 
                <div key={index}>
                    {
                    elem.type === "about" ?
                        <About elem={elem}/>
                    :
                    elem.type === "about-2" ?
                        <About2 elem={elem}/>
                    :
                    elem.type === "advantages" ?
                        <Advantages elem={elem}/>
                    :
                    elem.type === "advantages-2" ?
                        <FeatureCards elem={elem}/>
                    :
                    elem.type === "services-2" ?
                        <OurServices elem={elem}/>
                    :
                    elem.type === "workers" ?
                        <Workers elem={elem}/>
                    :
                    elem.type === "packages" ?
                        <Packages elem={elem}/>
                    :
                    elem.type === "reviews" ?
                        <Reviews elem={elem}/>
                    :
                    elem.type === "articles" ?
                        <OurBlog elem={elem}/>
                    :
                    elem.type === "form" ?
                        <ContactForm elem={elem}/>
                    :
                    elem.type === "partners" ?
                        <Partners elem={elem}/>
                    :
                    elem.type === "steps" ?
                        <WhyChooseUs elem={elem}/>
                    :
                    elem.type === "faq" ?
                        <FaqComponent elem={elem}/>
                    :
                    elem.type === "projects" ?
                        <ProjectsComponent elem={elem}/>
                    :
                    elem.type === "video-component" ?
                        <VideoComponent elem={elem}/>
                    :
                    elem.type === "gallery" ?
                        <Gallery elem={elem}/>
                    :
                        <></>
                    }
                </div>
            )}
        </div>
        </div>
    }</>
  )
}

export default ArticleDetail