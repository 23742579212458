import React from 'react'
import About from './About'
import About2 from './About2'
import FeatureCards from './FeatureCards'
import OurServices from './OurServices'
import Workers from './Workers'
import Packages from './Packages'
import Reviews from './Reviews'
import OurBlog from './OurBlog'
import ContactForm from './ContactForm'
import Partners from './Partners'
import FaqComponent from './FaqComponent'
import ProjectsComponent from './ProjectsComponent'
import VideoComponent from './VideoComponent'
import Gallery from './Gallery'
import ProjectsList from './ProjectsList'
import TextComponent from './TextComponent'
import TextImage from '../simple/TextImage'
import ProjectSteps from '../simple/ProjectSteps'
import img from "../../images/placeholder.png"
import Fancybox from './FancyBox'

const MediaDetailContainer = ({item}) => {
  return (
    <div className="blog_area blog-details-area pt-100 pb-100">
        <div className="container">		
            <div className="row">	
                <div className="col-12 media-detail">
                    {item.title && <h2>{item.title}</h2>}
                    <div className='date'>{item.published_at}</div>
                    <div dangerouslySetInnerHTML={{__html: item.description}}></div>
                    <Fancybox
                        options={{
                            Carousel: {
                            infinite: false,
                            },
                        }}
                    >
                        <div className='row mt-80'>
                            {item.gallery?.map((card, index) =>
                                <a href={card.url ? card.url : card.image} data-fancybox="gallery" className="col-sm-12 col-md-6 col-lg-4" key={index} >
                                    <div className='media-detail-card'>
                                        <img src={card.image ? card.image : img} alt={item.title}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; 
                                                currentTarget.src = img
                                            }}
                                        />
                                        {card.url && <span className='video-span'>
                                            <i className='fa fa-play'></i>
                                        </span>}
                                    </div>
                                </a>
                            )}
                        </div>
                    </Fancybox>        
                    {item?.elements?.map((elem, index) => 
                                <div key={index}>
                                    {
                                    elem.type === "about" ?
                                        <About elem={elem}/>
                                    :
                                    elem.type === "about-2" ?
                                        <About2 elem={elem}/>
                                    :
                                    elem.type === "advantages" ?
                                        <ProjectsList elem={elem}/>
                                    :
                                    elem.type === "advantages-2" ?
                                        <FeatureCards elem={elem}/>
                                    :
                                    elem.type === "services-2" ?
                                        <OurServices elem={elem}/>
                                    :
                                    elem.type === "workers" ?
                                        <Workers elem={elem}/>
                                    :
                                    elem.type === "packages" ?
                                        <Packages elem={elem}/>
                                    :
                                    elem.type === "reviews" ?
                                        <Reviews elem={elem}/>
                                    :
                                    elem.type === "articles" ?
                                        <OurBlog elem={elem}/>
                                    :
                                    elem.type === "form" ?
                                        <ContactForm elem={elem}/>
                                    :
                                    elem.type === "partners" ?
                                        <Partners elem={elem}/>
                                    :
                                    elem.type === "steps" ?
                                        <ProjectSteps elem={elem}/>
                                    :
                                    elem.type === "faq" ?
                                        <FaqComponent elem={elem}/>
                                    :
                                    elem.type === "projects" ?
                                        <ProjectsComponent elem={elem}/>
                                    :
                                    elem.type === "video-component" ?
                                        <VideoComponent elem={elem}/>
                                    :
                                    elem.type === "gallery" ?
                                        <Gallery elem={elem}/>
                                    :
                                    elem.type === "text-component" ?
                                        <TextComponent elem={elem}/>
                                    :
                                    elem.type === "opening-3" ?
                                        <TextImage elem={elem}/>
                                    :
                                        <></>
                                    }
                                </div>
                    )}
                </div>
            </div>
        </div>
    </div>
  )
}

export default MediaDetailContainer