import React from 'react'
import RecentArticles from '../simple/RecentArticles'
import ArticleCategories from '../simple/ArticleCategories'

const ArticleSidebar = () => {
  return (
    <div className="blog-left-side widget">
        <RecentArticles/>	
    </div>
  )
}

export default ArticleSidebar