import React from 'react'

const TextComponent = ({elem}) => {
  return (
    <div className="container">
        <div className='text-component'>
            {elem.title &&<div className="row">
                <div className="col-lg-12 wow fadeInRight mb-50" data-wow-delay="0.4s">
                    <div className="section_title text_center">
                        <div className="projects-title">
                                <h4 className='theme-color'>{elem.title}</h4>
                        </div>
                        <div className="em_bar">
                                <div className="em_bar_bg"></div>
                        </div>
                    </div>
                </div>
            </div>}
            <div dangerouslySetInnerHTML={{__html: elem.description}}></div>
        </div>
    </div>
  )
}

export default TextComponent