import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import EventsServices from '../../services/general/eventsServices'
import Pagination from './Pagination'
import EventCard from '../simple/EventCard'

const EventsContainer = () => {
    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(EventsServices.index)

  return (
    <div className="box_card_area pt-90 pb-80">
		<div className="container">
			<div className="row">
                {items?.items?.map((card, index) =>
                    <EventCard card={card} key={index}/>
                )}
			</div>
			<div className="row">
				<div className="col-md-12">
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
				</div>
			</div>
		</div>
	</div>
  )
}

export default EventsContainer