import React, { useEffect, useState } from 'react'
import img from "../../images/placeholder.png"

const About = ({elem}) => {
    const [banner, setBanner] = useState();
    const [banner2, setBanner2] = useState();
    const [text, setText] = useState();

    useEffect(() => {
        if(elem){
            setBanner(elem.elements?.find(it => it.type === "images")?.elements[0]);
            setBanner2(elem.elements?.find(it => it.type === "images")?.elements[1]);
            setText(elem.elements?.find(it => it.type === "text")?.elements)
        }
    }, [elem])

  return (
	<div className="about_area projects-back pt-100 pb-100" style={{backgroundImage: `url(${banner2})`}}>
		<div className="container">
			<div className="row">
				<div className="col-lg-6 col-md-6 col-sm-12 col-xs-6 wow fadeInLeft" data-wow-delay="0.4s">
					<div className="single_about_thumb mb-3">
						<div className="single_about_thumb_inner bounce-animate">
							<img src={banner} alt="about-us" 
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img
                                }}
                            />
						</div>
					</div>

				</div>
				<div className="col-lg-6 col-md-6 col-sm-12 col-xs-6">
					<div className="section_title text_left mb-40 mt-3 wow fadeInRight" data-wow-delay="0.4s">
						<div className="section_sub_title uppercase mb-3">
							<h6>{elem.subtitle}</h6>
						</div>
						<div className="section_main_title">
							<h1 dangerouslySetInnerHTML={{__html: elem.title}}></h1>
						</div>
						<div className="em_bar">
							<div className="em_bar_bg"></div>
						</div>
						<div className="section_content_text pt-4"
                            dangerouslySetInnerHTML={{__html: elem.description}}
                        ></div>
					</div>
					<div className="singel_about_left mb-30 wow fadeInUp" data-wow-delay="0.4s">
                        {text?.map((card, index) => 
                            <div className="singel_about_left_inner mb-3" key={index}>
                                <div className="about_icon mr-4">
                                    <div className="icon mt-3">
                                        <img src={card.image} alt="" 
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; 
                                                currentTarget.src = img
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="singel-about-content">
                                    <h5>{card.title}</h5>
                                    <p dangerouslySetInnerHTML={{__html: card.description}}></p>
                                </div>
                            </div>
                        )}
					</div>
				</div>

			</div>
		</div>
	</div>
  )
}

export default About