import React from 'react'
import MediaCard from '../simple/MediaCard'
import Pagination from './Pagination'
import useFetchItems from '../../hooks/useFetchItems'
import MediaServices from '../../services/general/mediaServices'

const MediaContainer = () => {
    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(MediaServices.index)

  return (
	<div className="box_card_area pt-90 pb-80">
		<div className="container">
			<div className="row">
                {items?.items?.map((card, index) =>
                    <MediaCard card={card} key={index}/>
                )}
			</div>
			<div className="row">
				<div className="col-md-12">
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
				</div>
			</div>
		</div>
	</div>
  )
}

export default MediaContainer