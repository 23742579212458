import React from 'react'
import { useLangContext } from '../../i18n/ProvideLang'
import { NavLink } from 'react-router-dom';
import img from "../../images/placeholder.png"

const ArticleCard = ({card}) => {
    const {lang} = useLangContext();

  return (
    <NavLink to={`/${lang}/articles/${card.slug}`}>
        <div className="post-slide1">
            <div className="post-img">
                <img src={card.image} alt="blog1"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; 
                        currentTarget.src = img
                    }}
                />                  
            </div>
            <div className="post-review">
                <div className="blog_details">
                    <span className="meta-date">{card.date}</span>
                </div><br/>
                <h3 className="post-title">{card.title}</h3>
                <p className="post-description" dangerouslySetInnerHTML={{__html: card.description}}></p>                            
            </div>
        </div> 
    </NavLink>
  )
}

export default ArticleCard