import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import ServiceServices from '../../services/general/serviceServices'
import ServiceCard from '../simple/ServiceCard'
import Pagination from './Pagination'

const ServicesContainer = ({item}) => {
    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(ServiceServices.index)

  return (
	<div className="our-service pt-100 pb-100">
		<div className="container">
            <div className="row">
                <div className="col-lg-12 wow fadeInRight" data-wow-delay="0.4s">
                    <div className="section_title text_center mb-50 mt-3">

                        <div className="section_sub_title uppercase mb-3">
                            <h6>{item.title}</h6>
                        </div>
                        <div className="section_main_title">
                            <h1>{item.subtitle}</h1>
                        </div>
                        <div className="em_bar">
                            <div className="em_bar_bg"></div>
                        </div>

                    </div>
                </div>
            </div>
			<div className="row">
                {items?.items?.map((card, index) =>
                    <div className="col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.4s" key={index}>
                        <ServiceCard card={card}/>
                    </div>
                )}
			</div>
            <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
		</div>
	</div>
  )
}

export default ServicesContainer