import React from 'react'
import CustomCollapse from '../simple/CustomCollapse'
import img from "../../images/placeholder.png"

const FaqComponent = ({elem}) => {
  return (
    <div className="faq_list pt-90 pb-80">
    <div className="container">
        <div className="row">
            <div className="col-lg-12 wow fadeInRight" data-wow-delay="0.4s">
                <div className="section_title text_center mb-50 mt-3">

                    <div className="section_sub_title uppercase mb-3">
                        <h6>{elem.subtitle}</h6>
                    </div>
                    <div className="section_main_title">
                        <h1>{elem.title}</h1>
                    </div>
                    <div className="em_bar">
                        <div className="em_bar_bg"></div>
                    </div>

                </div>
            </div>
        </div>
        <div className="row">
			<div className="col-lg-6 col-md-6 col-sm-12">
				<div className="faq__accordian-main-wrapper">
                    <img src={elem.image} alt="" 
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; 
                            currentTarget.src = img
                        }}
                    />
				</div>
			</div>
			<div className="col-lg-6 col-md-6 col-sm-12">
				<div className="faq__accordian-main-wrapper" id="faq__accordian-main-wrapper">
                    {elem?.elements?.map((card, index) =>
					    <CustomCollapse card={card} key={index}/>
                    )}
				</div>
			</div>
		</div>
    </div>
</div>
  )
}

export default FaqComponent