import React from 'react'
import { NavLink } from 'react-router-dom'
import { useLangContext } from '../../i18n/ProvideLang'

const PageTop = ({item, parentLink, parentName}) => {
    const {lang, localizationsItems} = useLangContext();

  return (
    <div className="breatcome_topheader d-flex align-items-center"
    style={{backgroundImage: `url(${item?.detail_image})`}}
    >
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="breatcome_title">
                        <div className="breatcome_title_inner pb-2">
                            <h2>{item?.title}</h2>
                        </div>
                        <div className="breatcome_content">
                            <ul>
                                <li>
                                    <NavLink to={`/${lang}`}>{localizationsItems?.main}</NavLink> 
                                    <i className="fa fa-angle-right"></i> 
                                    {parentLink && 
                                        <>
                                            <NavLink to={parentLink}>{parentName}</NavLink> 
                                            <i className="fa fa-angle-right"></i> 
                                        </>}
                                    <span>{item?.title}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PageTop